`use strict`;

//import Lazyload from "../libs/lazyload";

class Homepage {
  constructor() {
    /*let lazyload = new Lazyload();
    lazyload.initLazyLoading();*/
  }
}

new Homepage();